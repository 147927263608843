<template>
  <div class="nav-bar-pref-container">
    <b-navbar toggleable="lg" type="dark" class="main-container-nav">
      <b-navbar-brand>
        <router-link to="/">
          <img class="logo" src="@/assets/brasao.png" />
        </router-link>
      </b-navbar-brand>
      <b-navbar-toggle target="sidebar-footer"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="menu-itens">
          <b-nav-item
            href="http://45.174.220.245:8079/transparencia/"
            target="_blank"
          >
            PORTAL DA TRANSPARÊNCIA
          </b-nav-item>
          <b-nav-item-dropdown text="LEIS E LEGISLAÇÕES" right>
            <b-dropdown-item @click="findDecreto()">DECRETOS</b-dropdown-item>
            <b-dropdown-item @click="findLegislacao()"
              >LEGISLAÇÃO</b-dropdown-item
            >
            <!-- <b-dropdown-item
              href="https://pmsbribasdoriopardo.wixsite.com/pmsb"
              target="_blank"
              >PMSB</b-dropdown-item
            > -->
          </b-nav-item-dropdown>
          <b-nav-item-dropdown text="SERVIÇOS" right>
            <b-dropdown-item
              href="http://45.174.220.245:8056/issweb/home.jsf"
              target="_blank"
              >EMISSÃO DE NFe</b-dropdown-item
            >
            <b-dropdown-item
              href="https://www.gov.br/empresas-e-negocios/pt-br/empreendedor/servicos-para-mei/nota-fiscal"
              target="_blank"
              >EMISSÃO DE NFe - MEI</b-dropdown-item
            >
            <b-dropdown-item
              href="http://45.174.220.245:8056/servicosweb/home.jsf"
              target="_blank"
              >EMISSÃO DE CND</b-dropdown-item
            >
            <b-dropdown-item
              href="https://ribas.esaude.genesiscloud.tec.br/publico/saude-transparente"
              target="_blank"
              >SAÚDE TRANSPARENTE</b-dropdown-item
            >
            <b-dropdown-item
              href="https://ribas.esaude.genesiscloud.tec.br/publico/saude-transparente/consultar-estoque?departamentosTransparentes%5B0%5D=atendimentos_da_farmacia&departamentosTransparentes%5B1%5D=atendimentos_do_laboratorio&departamentosTransparentes%5B2%5D=atendimentos_da_atencao_basica&departamentosTransparentes%5B3%5D=atendimentos_do_hospital&departamentosTransparentes%5B4%5D=atendimentos_da_especialidade&departamentosTransparentes%5B5%5D=vigilancia_sanitaira_pessoa_fisica_e_juridica&departamentosTransparentes%5B6%5D=vacinas_esus"
              target="_blank"
              >FARMÁCIA BÁSICA</b-dropdown-item
            >
            <b-dropdown-item
              href="http://45.174.220.245:8057/sipweb/trabalhador/login/login.xhtml"
              target="_blank"
              >HOLERITE WEB</b-dropdown-item
            >
            <b-dropdown-item
              href="https://ribasdoriopardo.flowdocs.com.br"
              target="_blank"
              >PROTOCOLO DIGITAL</b-dropdown-item
            >
            <b-dropdown-item :to="{ path: '/terra-nua' }"
              >VALOR DE ITR / ITBI</b-dropdown-item
            >
            <b-dropdown-item
              href="https://educacao.ribasdoriopardo.genesis.tec.br/matriculadigital"
              target="_blank"
              >MATRÍCULA DIGITAL</b-dropdown-item
            >
          </b-nav-item-dropdown>
          <b-nav-item :to="{ path: '/diribas' }">DIÁRIO OFICIAL</b-nav-item>
          <b-nav-item :to="{ path: '/editais/0/10' }"
            >LICITAÇÕES E EDITAIS</b-nav-item
          >
          <b-nav-item-dropdown text="PODER EXECUTIVO" right>
            <b-dropdown-item
              href="https://ribas-publications.s3.sa-east-1.amazonaws.com/ESTRUTURA-ADMINISTRATIVA-2023.pdf"
              target="_blank"
              >ESTRUTURA ADMINISTRATIVA</b-dropdown-item
            >
            <b-dropdown-item :to="{ path: '/secretarias' }"
              >SECRETARIAS</b-dropdown-item
            >
            <b-dropdown-item 
              href="https://ribas-publications.s3.sa-east-1.amazonaws.com/ORGANOGRAMA-2023.pdf"
              target="_blank"
              >ORGANOGRAMA</b-dropdown-item
            >
          </b-nav-item-dropdown>
          <b-nav-item-dropdown text="RIBAS" right>
            <b-dropdown-item :to="{ path: '/ribas/historia' }"
              >HISTÓRIA</b-dropdown-item
            >
            <b-dropdown-item :to="{ path: '/galerias/listagem/0/10' }"
              >GALERIA</b-dropdown-item
            >
            <b-dropdown-item :to="{ path: '/ribas/simbolos-oficiais' }"
              >SÍMBOLOS OFICIAIS</b-dropdown-item
            >
            <b-dropdown-item
              href="https://ribas-publications.s3.sa-east-1.amazonaws.com/Apresenta%C3%A7%C3%A3o+do+Mapa+Ribas+-+PROPEQ+-+30+slides.pptx.pdf"
              target="_blank"
              >MAPA DE OPORTUNIDADES</b-dropdown-item
            >
            <b-dropdown-item
              href="https://ribas-publications.s3.sa-east-1.amazonaws.com/SEBRAE_Relat%C3%B3rio+PDE+Cidade+Empreendedora-Ribas+do+Rio+Pardo-(Final).pdf"
              target="_blank"
              >PLANO DE DESENVOLVIMENTO ECONÔMICO</b-dropdown-item
            >
            <b-dropdown-item
              href="https://ribasdoriopardo.ms.gov.br/publicacoes/3863/plano-de-manejo-apa-anhandui-pardo"
              target="_blank"
              >PLANO DE MANEJO APA ANHADUÍ-PARDO</b-dropdown-item
            >
            <b-dropdown-item
              href="https://www.ribasdoriopardo.ms.gov.br/publicacoes/3942/politica-nacional-aldir-blanc---pnab"
              target="_blank"
              >POLÍTICA NACIONAL ALDIR BLANC</b-dropdown-item
            >
          </b-nav-item-dropdown>
          <b-nav-item :to="{ path: '/ouvidoria' }">OUVIDORIA / e-SIC</b-nav-item>
        </b-navbar-nav>
        <router-link to="/login" v-if="!userName"
          ><div class="nav-login">
            <span><i class="far fa-user"></i>Login</span>
          </div></router-link
        >
        <div class="nav-login" v-else>
          <b-nav-item-dropdown :text="'Olá, ' + userName" right>
            <b-dropdown-item @click="logout">Sair</b-dropdown-item>
            <b-dropdown-item :to="{ path: '/dashboard' }"
              >Dashboard</b-dropdown-item
            >
          </b-nav-item-dropdown>
        </div>
      </b-collapse>
    </b-navbar>
    <b-sidebar
      id="sidebar-footer"
      aria-label="Sidebar with custom footer"
      shadow
      backdrop
      no-header
    >
      <template #footer="{}">
        <div class="d-flex bg-dark text-light align-items-center py-2">
          <strong v-if="userName" class="mr-auto">Olá, {{ userName }}</strong>
          <strong v-else class="mr-auto"></strong>
          <b-button v-if="!userName" size="sm" @click="login"
            ><i style="margin-right: 5px" class="fas fa-sign-in-alt"></i
            >Entrar</b-button
          >
          <b-button v-else size="sm" @click="logout"
            >Sair<i style="margin-left: 5px" class="fas fa-sign-out-alt"></i
          ></b-button>
        </div>
      </template>
      <div class="mobile-list">
        <nav class="mb-3">
          <b-nav vertical>
            <div class="icons-mobile">
              <i class="fas fa-search-dollar"></i>
              <b-nav-item
                href="http://45.174.220.245:8079/transparencia/"
                target="_blank"
                >PORTAL DA TRANSPARÊNCIA</b-nav-item
              >
            </div>
            <div class="icons-mobile" v-b-toggle="'executivo'">
              <i class="fas fa-code-branch"></i>
              <b-nav-item
                >PODER EXECUTIVO <i class="fas fa-caret-down"></i
              ></b-nav-item>
            </div>
            <b-collapse id="executivo">
              <b-dropdown-item :to="{ path: '/secretarias' }"
                >SECRETARIAS</b-dropdown-item
              >
            </b-collapse>
            <div class="icons-mobile" v-b-toggle="'leis'">
              <i class="far fa-file-alt"></i>
              <b-nav-item
                >LEIS E LEGISLAÇÕES <i class="fas fa-caret-down"></i
              ></b-nav-item>
            </div>
            <b-collapse id="leis">
              <b-dropdown-item @click="findDecreto()">DECRETOS</b-dropdown-item>
              <b-dropdown-item @click="findLegislacao()"
                >LEGISLAÇÃO</b-dropdown-item
              >
              <!-- <b-dropdown-item
                href="https://pmsbribasdoriopardo.wixsite.com/pmsb"
                target="_blank"
                >PMSB</b-dropdown-item
              > -->
            </b-collapse>
            <div class="icons-mobile" v-b-toggle="'servicos'">
              <i class="fas fa-wrench"></i>
              <b-nav-item
                >SERVIÇOS <i class="fas fa-caret-down"></i
              ></b-nav-item>
            </div>
            <b-collapse id="servicos">
              <b-dropdown-item
                href="http://45.174.220.245:8056/issweb/home.jsf"
                target="_blank"
                >EMISSÃO DE NFe</b-dropdown-item
              >
              <b-dropdown-item
                href="https://www.gov.br/empresas-e-negocios/pt-br/empreendedor/servicos-para-mei/nota-fiscal"
                target="_blank"
                >EMISSÃO DE NFe - MEI</b-dropdown-item
              >
              <b-dropdown-item
                href="http://45.174.220.245:8056/servicosweb/home.jsf"
                target="_blank"
                >EMISSÃO DE CND</b-dropdown-item
              >
              <b-dropdown-item
                href="http://45.174.220.242:8085/solicitacao/open.do?action=open&sys=FOR"
                target="_blank"
                >FORNECIMENTO MUNICIPAL</b-dropdown-item
              >
              <b-dropdown-item
                href="http://45.174.220.242:8085/requisicao-compra/#/login"
                target="_blank"
                >REQUISIÇÃO MUNICIPAL</b-dropdown-item
              >
              <b-dropdown-item
                href="http://45.174.220.245:8057/sipweb/trabalhador/login/login.xhtml"
                target="_blank"
                >HOLERITE WEB</b-dropdown-item
              >
              <b-dropdown-item
                href="https://ribasdoriopardo.flowdocs.com.br"
                target="_blank"
                >PROTOCOLO DIGITAL</b-dropdown-item
              >
              <b-dropdown-item :to="{ path: '/terra-nua' }"
                >VALOR DE ITR / ITBI</b-dropdown-item
              >
              <b-dropdown-item
                href="http://45.174.220.245:8056/servicosweb/home.jsf"
                target="_blank"
                >IPTU/LIXO 2024</b-dropdown-item
              >
            </b-collapse>
            <div class="icons-mobile">
              <i class="far fa-newspaper"></i>
              <b-nav-item :to="{ path: '/diribas' }">DIÁRIO OFICIAL</b-nav-item>
            </div>
            <div class="icons-mobile">
              <i class="fas fa-gavel"></i>
              <b-nav-item :to="{ path: '/editais/0/10' }"
                >LICITAÇÕES E EDITAIS</b-nav-item
              >
            </div>
            <div class="icons-mobile">
              <i class="fas fa-bullhorn"></i>
              <b-nav-item :to="{ path: '/ouvidoria' }">OUVIDORIA</b-nav-item>
            </div>
            <div class="icons-mobile" v-b-toggle="'ribas'">
              <i class="far fa-map"></i>
              <b-nav-item>RIBAS <i class="fas fa-caret-down"></i></b-nav-item>
            </div>
            <b-collapse id="ribas">
              <b-dropdown-item :to="{ path: '/ribas/historia' }"
                >HISTÓRIA</b-dropdown-item
              >
              <b-dropdown-item :to="{ path: '/galerias/listagem/0/10' }"
                >GALERIA</b-dropdown-item
              >
              <b-dropdown-item :to="{ path: '/ribas/simbolos-oficiais' }"
                >SÍMBOLOS OFICIAIS</b-dropdown-item
              >
              <b-dropdown-item
                href="https://ribas-publications.s3.sa-east-1.amazonaws.com/Apresenta%C3%A7%C3%A3o+do+Mapa+Ribas+-+PROPEQ+-+30+slides.pptx.pdf"
                target="_blank"
                >MAPA DE OPORTUNIDADES</b-dropdown-item
              >
              <b-dropdown-item
                href="https://ribas-publications.s3.sa-east-1.amazonaws.com/SEBRAE_Relat%C3%B3rio+PDE+Cidade+Empreendedora-Ribas+do+Rio+Pardo-(Final).pdf"
                target="_blank"
                >PLANO DE DESENVOLVIMENTO ECONÔMICO</b-dropdown-item
              >
              <b-dropdown-item
                :to="{ path: '/consulta-publica-decreto-102-2022' }"
                >CONSULTA PÚBLICA</b-dropdown-item
              >
            </b-collapse>
            <div v-if="userId" class="icons-mobile">
              <i class="fas fa-desktop"></i>
              <b-nav-item :to="{ path: '/dashboard' }">DASHBOARD</b-nav-item>
            </div>
          </b-nav>
        </nav>
      </div>
    </b-sidebar>
    <hr class="separator" />
  </div>
</template>
    
<script>
import { isMobile } from "mobile-device-detect";
import AuthenticationService from "../services/AuthenticationService";

export default {
  components: {},
  name: "NavBarPref",
  data() {
    return {
      menuActive: false,
      userName: null,
      userId: null,
    };
  },
  computed: {
    isMobile() {
      return isMobile;
    },
  },
  mounted() {
    if (localStorage.getItem("userName"))
      this.userName = localStorage.getItem("userName");

    this.userId = localStorage.getItem("userId");

    this.$root.$on("unauthorized", this.logout);
  },
  methods: {
    login() {
      this.$router.push({ path: "login" });
    },
    async logout() {
      if (this.$cookies.get("remember_id"))
        await AuthenticationService.removeSession(
          localStorage.getItem("userId")
        );
      this.$cookies.remove("remember_id");
      this.$cookies.remove("remember_name");
      localStorage.removeItem("userName");
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      this.userName = null;
      this.$router.go();
    },
    async findLegislacao() {
      this.$router.push({
        name: "law-view",
        params: {
          type: "leis-e-legislacoes",
          tag: "todas",
          page: 0,
          size: 10,
          text: null,
        },
      });
    },
    findDecreto() {
      this.$router.push({
        name: "law-view",
        params: {
          type: "decretos",
          tag: "todas",
          page: 0,
          size: 10,
          text: null,
        },
      });
    },
  },
};
</script>
<style lang="scss">
.main-container-nav {
  background-color: #204991;
  padding: unset !important;
  height: 60px;
  #nav-collapse {
    @media (max-width: 990px) {
      z-index: 1;
    }
  }
  .navbar-brand {
    @media (max-width: 1100px) {
      margin-right: 0;
    }
  }
  .logo {
    height: 60px;
    padding-left: 30px;
    @media (max-width: 1200px) {
      height: 40px;
      padding-left: unset;
    }
  }
  .navbar-collapse {
    justify-content: space-between;
    .menu-itens {
      width: 80%;
      justify-content: space-between;
      padding-left: 10px;
      align-items: center;
      @media (max-width: 990px) {
        align-items: normal;
      }
      .nav-link {
        color: white !important;
        font-family: Raleway;
        font-weight: bold;
        font-size: 13px;
        @media (max-width: 1100px) {
          font-size: 12px;
        }
        @media (max-width: 768px) {
          font-size: inherit;
        }
      }
      ul {
        background-color: #204991;
        outline: none !important;
        outline-width: 0 !important;
        box-shadow: none;
        li {
          a {
            font-size: 12px;
          }
        }
      }
      a {
        color: white;
        text-decoration: none;
        background-color: #204991;
        outline: none !important;
        outline-width: 0 !important;
        box-shadow: none;
      }
      a:hover {
        background-color: #3265bf;
        transition: 0.3s;
        border-radius: 5px;
      }
    }
    .nav-login {
      color: white;
      margin-right: 20px;
      font-size: 12px;
      li {
        list-style-type: none;
        a {
          color: white;
          text-decoration: none;
          outline: none !important;
          outline-width: 0 !important;
          box-shadow: none;
          background-color: #204991;
        }
        a:hover {
          background-color: #3265bf;
          transition: 0.3s;
          border-radius: 5px;
        }
        ul {
          background-color: #204991;
          outline: none !important;
          outline-width: 0 !important;
          box-shadow: none;
          li {
            a {
              font-size: 12px;
            }
          }
        }
      }
      span {
        i {
          margin-right: 5px;
        }
      }
    }
  }
}
.separator {
  border: 3px solid #efd02f;
  margin: unset;
}
.b-sidebar {
  footer {
    padding: 0;
    button {
      margin-right: 5px;
    }
  }
  strong {
    margin-left: 10px;
  }
  .b-sidebar-body {
    background-color: #204991;
    .nav-link {
      padding: 0;
    }
  }
  .mobile-list {
    text-align: initial;
    a {
      color: white;
      text-decoration: none;
      font-size: 15px;
    }
    #leis,
    #servicos,
    #ribas {
      margin-left: 30px;
      li {
        a {
          padding-left: 0;
        }
      }
    }
    .icons-mobile {
      display: flex;
      padding: 0.5rem 1rem;
      align-items: center;
      margin-top: 5px;
      border-bottom: 1px solid #a8a8a8;
      i {
        color: white;
        margin-right: 10px;
        font-size: 20px;
      }
    }
    .collapse {
      text-decoration: none;
      li {
        margin-top: 5px;
        border-bottom: 1px solid #a8a8a8;
        a {
          &:hover {
            background-color: #3265bf;
            transition: 0.3s;
          }
        }
      }
    }
  }
}
</style>